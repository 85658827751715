import React, {useEffect} from 'react';
import 'typeface-roboto';
import {Provider} from "react-redux";
import {configureStore} from '@reduxjs/toolkit'
import './App.css';
import {createTheme, MuiThemeProvider, responsiveFontSizes} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import rootReducer from './reducers'
import AppContainer from "./components/AppContainer";
import disableBrowserBackButton from 'disable-browser-back-navigation';
import ReactGA from 'react-ga';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    palette: {
        type: "light",
        primary: {
            main: '#efa833'
        },
        secondary: {
            main: '#448ca0'
        },
        // background: {
        //     default: "#f2f2f2"
        // }
    }
});
theme = responsiveFontSizes(theme);

ReactGA.initialize('UA-6040674-45');

ReactGA.pageview('/app');

disableBrowserBackButton();

const store = configureStore({
    reducer: rootReducer
});

export default function  App() {
    useEffect(() => {
        console.log('useEffect fired for application');
        disableBrowserBackButton();
    }, []);


    return (
        <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline/>
                <div>
                    <AppContainer/>
                </div>
            </MuiThemeProvider>
        </Provider>
    );
};
