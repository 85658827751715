import {createSlice} from '@reduxjs/toolkit'

const preWorkoutDataSlice = createSlice({
    name: 'preWorkoutData',
    initialState: {},
    reducers: {
        storePreWorkoutData(state, action) {
            console.log('incoming pre workout data', JSON.stringify(action.payload));
            return action.payload;
        },
        resetPreWorkoutData() {
            return  {};
        }
    }
});

export const {storePreWorkoutData, resetPreWorkoutData} = preWorkoutDataSlice.actions;

export default preWorkoutDataSlice.reducer