import React from 'react';
import Typography from "@material-ui/core/Typography";

export default function Allergy(props) {

    return (
        <div>
            {props.value ?
                <Typography component="div">
                    <strong>{props.label}:</strong> {props.value}
                </Typography>
            : ''}
        </div>
    )

}