import {createSlice} from '@reduxjs/toolkit'

let nextExerciseIndex = 0;

const exercisesSlice = createSlice({
    name: 'exercises',
    initialState: [],
    reducers: {
        addExercise(state, action) {
            state.push({id: nextExerciseIndex++, name: action.payload.name, type: action.payload.type, promptCount: 0, finished: false});
        },
        removeExercise(state, action) {
            console.log('removing ', action.payload);
            return state.filter(function(e) {
                return e.id !== action.payload.id
            });
        },
        updateExercise(state, action) {
            const incomingExercise = action.payload;
            console.log('incoming exercise', JSON.stringify(incomingExercise));
            const exercise = state.find(e => e.id === incomingExercise.id);
            if (exercise) {
                const keys = Object.keys(incomingExercise);
                for (const key in keys) {
                    const attribute = keys[key];
                    exercise[attribute] = incomingExercise[attribute];
                }
            }
            console.log('altered exercise', JSON.stringify(exercise));
        },
        resetExercises() {
            return [];
        }
    }
});

export const {addExercise, removeExercise, updateExercise, resetExercises} = exercisesSlice.actions;

export default exercisesSlice.reducer