import React from 'react';
import {useForm} from "react-hook-form";
import Box from "@material-ui/core/Box";
import {useDispatch} from "react-redux";
import {nextEnrollmentStep} from "../../../reducers/enrollmentStepSlice";
import {storeEnrollmentProfiles} from "../../../reducers/enrollmentDataSlice";
import BehaviorProfile from "./BehaviorProfile";
import PhysicalProfile from "./PhysicalProfile";
import EnrollmentNavigationButtons from "../buttons/EnrollmentNavigationButtons";

export default function ProfileSection(props) {

    const {control, register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const onSubmit = data => {
        console.log('result of physical profile form:', JSON.stringify(data));
        dispatch(storeEnrollmentProfiles(data));
        dispatch(nextEnrollmentStep());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section style={!props.show ? {display: "none"} : {}}>
                <Box pt={2} maxWidth={800} mx="auto">
                    <PhysicalProfile register={register} errors={errors} control={control}/>
                    <BehaviorProfile register={register} errors={errors}/>
                </Box>
                <EnrollmentNavigationButtons/>
            </section>
        </form>
    )

}