import React from "react";
import ReactGA from 'react-ga';
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import WaiversQuestions from "./WaiversQuestions";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PreviousEnrollmentStepButton from "../buttons/PreviousEnrollmentStepButton";
import DiscardEnrollmentButton from "../buttons/DiscardEnrollmentButton";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {setNavigationToClientDashboardPage} from "../../../reducers/navigateSlice";
import {getClient} from "../../../api/api";
import {openGlobalDialog} from "../../../reducers/globalDialogSlice";

export default function WaiversSection(props) {

    const enrollmentData = useSelector(store => store.enrollmentData);
    const client = useSelector(store => store.client);
    const {control, register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    const handleClose = () => {
        dispatch(setNavigationToClientDashboardPage());
        setOpen(false);
    };

    const getClientName = () => {
        if (enrollmentData.clientInfo) {
            return enrollmentData.clientInfo.clientName;
        } else {
            return '';
        }
    };

    const onSubmit = data => {

        setRequestInProgress(true);

        const enrollmentFinal = {...enrollmentData, waivers: data};
        console.log(JSON.stringify(enrollmentFinal));

        getClient().post('/api/enrollment/' + client.memberId, enrollmentFinal)
            .then((response) => {
                console.log(response);
                setOpen(true);
                ReactGA.event({
                    category: 'Enrollment',
                    action: 'Completed'
                });
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorDetails: error.message}));
            })
            .then(() => {
                setRequestInProgress(false);
            });
    };

    return (
        <div style={!props.show ? {display: "none"} : {}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box pt={2} maxWidth={800} mx="auto">
                    <WaiversQuestions errors={errors} register={register} control={control} />
                    <Grid container justify="center" style={{paddingTop: 40, paddingBottom: 20}}>
                        {!props.isFirstStep ?
                            <Box component="span" m={0.5}>
                                <PreviousEnrollmentStepButton/>
                            </Box>
                            : ''}
                        <Box component="span" m={0.5}>
                            <DiscardEnrollmentButton/>
                        </Box>
                        <Box component="span" m={0.5}>
                            <Button type="submit" variant="contained" color="primary" endIcon={<CheckIcon/>} disabled={requestInProgress}>
                                Submit Enrollment
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </form>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="enrollment-subbmitted-alert-dialog-title"
                aria-describedby="enrollment-subbmitted-alert-dialog-description"
            >
                <DialogTitle id="enrollment-subbmitted-alert-dialog-title">{"Enrollment Submitted"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="enrollment-subbmitted-alert-dialog-description">
                        The enrollment has been successfully submitted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}