import React, {useEffect} from 'react';
import Contact from "./Contact";
import MedicalContact from "./MedicalContact";
import MembershipInfo from "./MembershipInfo";
import {useForm} from "react-hook-form";
import Box from "@material-ui/core/Box";
import {useDispatch} from "react-redux";
import {nextEnrollmentStep} from "../../../reducers/enrollmentStepSlice";
import {storeEnrollmentContactInfo} from "../../../reducers/enrollmentDataSlice";
import EnrollmentNavigationButtons from "../buttons/EnrollmentNavigationButtons";
import {getClient} from "../../../api/api";
import {openGlobalDialog} from "../../../reducers/globalDialogSlice";
import PrimaryContact from "./PrimaryContact";

export default function ContactInfoSection(props) {

    const {register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const [states, setStates] = React.useState([]);

    useEffect(() => {
        console.log('fetching states');
        getClient().get('/api/states')
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve list of states.', errorDetails: error.message}));
            });
    }, [dispatch]);

    const onSubmit = data => {
        console.log('result of enrollment form:', data);
        dispatch(storeEnrollmentContactInfo(data));
        dispatch(nextEnrollmentStep());
    };

    return (
        <section style={!props.show ? {display: "none"} : {}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box pt={2} maxWidth={800} mx="auto">
                    <PrimaryContact prefix="client" label="Client" register={register} errors={errors} states={states}  nameRequired={true} phoneRequired={false} emailRequired={false}/>
                    <Contact prefix="parentGuardian" label="Parent / Guardian" register={register} errors={errors} states={states}  nameRequired={true} phoneRequired={true} emailRequired={true}/>
                    <Contact prefix="caregiver" label="Caregiver / Authorized Party" register={register} errors={errors} states={states} nameRequired={false} phoneRequired={false} emailRequired={false}/>
                    <MedicalContact prefix="medicalContact" label="Medical Contact" register={register} errors={errors} />
                    <MembershipInfo register={register} errors={errors}/>
                </Box>

                <EnrollmentNavigationButtons isFirstStep={true}/>
            </form>
        </section>
    )

}