import React from 'react';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {getClient} from "../../api/api";
import {useDispatch, useSelector} from "react-redux";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";

export default function ClientReports() {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const handleLogiTrainerLogin = () => {
        let windowReference = window.open();
        getClient().post('/api/logi/trainer/login/member/' + client.memberId)
            .then((response) => {
                // Can't do a straight window.open, doesn't work on ios currently
                windowReference.location = response.data;
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not log into Logi.', errorDetails: error.message}));
            });
    };

    return (
        <Box mx="auto">
            <Button variant="contained" style={{backgroundColor: "white"}} onClick={handleLogiTrainerLogin}>Reports</Button>
        </Box>
    );
}