import React from 'react';
import ReactGA from 'react-ga';
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {setNavigationToWorkoutForm} from "../../reducers/navigateSlice";
import {resetWorkoutStep} from "../../reducers/workoutStepSlice";
import {resetPreWorkoutData} from "../../reducers/preWorkoutDataSlice";
import {resetExercises} from "../../reducers/exercisesSlice";
import {setClient} from "../../reducers/clientSlice";

export default function StartWorkoutButton() {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const startWorkout = () => {
        // Clear any previous workout data
        dispatch(resetWorkoutStep());
        dispatch(resetPreWorkoutData());
        dispatch(resetExercises());

        dispatch(setClient(client));
        dispatch(setNavigationToWorkoutForm());

        ReactGA.event({
            category: 'Workout',
            action: 'Start'
        });
    };

    return (
        <Button onClick={startWorkout} type="submit" variant="contained" color="primary">Start a New Workout</Button>
    );
}