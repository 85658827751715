import React from 'react';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {useForm} from "react-hook-form";
import Box from "@material-ui/core/Box";
import {useDispatch} from "react-redux";
import {storeEnrollmentGoals} from "../../../reducers/enrollmentDataSlice";
import Goal from "./Goal";
import {nextEnrollmentStep} from "../../../reducers/enrollmentStepSlice";
import EnrollmentNavigationButtons from "../buttons/EnrollmentNavigationButtons";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

export default function GoalsSection(props) {

    const {register, errors, handleSubmit} = useForm();

    const dispatch = useDispatch();

    const [goals, setGoals] = React.useState([{id: 0}]);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const addGoal = () => {
        const index = goals.length;
        setGoals([...goals, {id: index}]);
    };

    const deleteGoal = () => {
        const newList = goals.filter(goal => goal.id !== goals.length - 1);
        setGoals(newList);
        handleClose();
    };

    const getAddGoalButtonLabel = () => {
        if (goals.length === 0) {
            return 'Add Goal';
        } else {
            return 'Add Another Goal';
        }
    };

    const onSubmit = data => {
        console.log('result of goals form:', JSON.stringify(data));
        dispatch(storeEnrollmentGoals(data));
        dispatch(nextEnrollmentStep());
    };

    return (
        <section style={!props.show ? {display: "none"} : {}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {goals.map((goal, key) =>
                    <Box key={key} my={2}>
                        <Goal goal={goal} register={register} errors={errors}/>
                    </Box>
                )}

                <Grid container justify="center" style={{paddingTop: 20, paddingBottom: 20}}>
                    <Box component="span" m={0.5}>
                        <Button variant="contained" onClick={addGoal}>{getAddGoalButtonLabel()}</Button>
                    </Box>
                    {goals.length ?
                        <Box component="span" m={0.5}>
                            <Button variant="contained" onClick={handleClickOpen}>Delete Goal</Button>
                        </Box>
                        : ''}
                </Grid>

                <Box mx="auto" maxWidth={800} pt={2}>
                    <Divider />
                </Box>

                <EnrollmentNavigationButtons/>
            </form>
            <Dialog
                open={open}
                aria-labelledby="delete-goal-alert-dialog-title"
                aria-describedby="delete-goal-alert-dialog-description"
            >
                <DialogTitle id="delete-goal-alert-dialog-title">{"Delete Goal: Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="enrollment-workout-alert-dialog-description">
                        Are you sure you want to delete Goal #{goals.length}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        No, Keep It
                    </Button>
                    <Button onClick={deleteGoal}>
                        Yes, Delete It
                    </Button>
                </DialogActions>
            </Dialog>
        </section>


    )

}