import React from 'react';
import ExercisePrompts from "./ExercisePrompts";
import ExerciseEquipment from "./ExerciseEquipment";
import LabelAndValue from "./LabelAndValue";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

export default function Exercise(props) {

    const exercise = props.exercise;

    const determineLabel = () => {
        let label = exercise.exerciseName;
        if (exercise.exerciseType) {
            label += ' (' + exercise.exerciseType + ')'
        }
        return label;
    }

    return (
        <Card variant="outlined" style={{height: '100%'}}>
            <CardContent>
                <Typography component="h2">{determineLabel()}</Typography>
                <ExerciseEquipment label="Details: " exercise={exercise} my={0}/>
                <ExercisePrompts exercise={exercise} />
                <LabelAndValue label="Client Partially Completed Exercise: " value={exercise.clientPartiallyCompletedExercise} />
                <LabelAndValue label="Notes: " value={exercise.notes} />
            </CardContent>
        </Card>
    );
}