import React from 'react';
import Typography from "@material-ui/core/Typography";

export default function ContactDetails(props) {
    return (
        <div>
            <Typography component="div">
                <strong>{props.label}:</strong> {props.name ? props.name : 'N/A'}
                {props.phone ?
                    <a style={{marginLeft: 4}} href={'tel:' + props.phone}>{props.phone}</a>
                : ''
                }
            </Typography>
        </div>
    )
}