import React from 'react';
import {useSelector} from "react-redux";
import Login from "./login/Login";
import LandingPage from "./landing/LandingPage";
import Workout from "./workout/Workout";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import WorkoutDrawer from "./workout/WorkoutDrawer";
import EnrollmentForm from "./enrollment/EnrollmentForm";
import GoalMaintenance from "./goals/GoalMaintenance";
import ClientAssessment from "./assessment/ClientAssessment";
import AdminLandingPage from "./landing/AdminLandingPage";
import ClientDashboard from "./client/ClientDashboard";

export default function Navigate() {

    const navigationStep = useSelector(store => store.navigationStep);
    const client = useSelector(store => store.client);

    return (
        <div>
            <div style={{backgroundColor: '#448ca0'}}>&nbsp;</div>
            <AppBar position="sticky" style={{backgroundColor: '#d7d7d7'}}>
                <Toolbar>
                    {client ?
                        <span>
                            <Grid container justify="flex-start">
                                <WorkoutDrawer/>
                            </Grid>
                        </span>
                        : ''}
                    <Grid container justify="flex-end">
                        <img style={{width: 100, height: 47}} src="/valemee-logo.png" alt="Valemee Logo"/>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div>
                {navigationStep === 0 ? <Login/> : ''}
                {navigationStep === 1 ? <LandingPage/> : ''}
                {navigationStep === 2 ? <EnrollmentForm/> : ''}
                {navigationStep === 3 ? <Workout/> : ''}
                {navigationStep === 4 ? <GoalMaintenance/> : ''}
                {navigationStep === 5 ? <ClientAssessment/> : ''}
                {navigationStep === 6 ? <AdminLandingPage/> : ''}
                {navigationStep === 7 ? <ClientDashboard/> : ''}
            </div>
        </div>
    );
}