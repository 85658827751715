import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import {Container, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import GoalEditor from "./GoalEditor";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch, useSelector} from "react-redux";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";
import {useForm} from "react-hook-form";
import {removeClient} from "../../reducers/clientSlice";
import {setNavigationToClientDashboardPage} from "../../reducers/navigateSlice";

export default function GoalMaintenance() {

    const {register, errors, handleSubmit} = useForm();
    const client = useSelector(store => store.client);

    const dispatch = useDispatch();

    const [goals, setGoals] = React.useState([]);
    const [addGoalDialogIsOpen, setAddGoalDialogIsOpen] = React.useState(false);
    const [targetDate, setTargetDate] = React.useState(null);

    useEffect(() => {
        console.log('useEffect fired for loading goals');
        updateGoals();
    }, []);

    const updateGoals = () => {
        getClient().get('/api/client/' + client.memberId + '/goals/')
            .then((response) => {
                setGoals(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve goals.', errorDetails: error.message}));
            });
    };

    const addGoal = (data) => {
        console.log('Handler for adding goal clicked');
        closeAddGoalDialog();
        getClient().post('/api/client/' + client.memberId + '/goal/', data)
            .then((response) => {
                updateGoals();
                ReactGA.event({
                    category: 'Goal Editing',
                    action: 'Added Goal'
                });
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not add goal.', errorDetails: error.message}));
            });
    };

    const discardGoal = (id) => {
        console.log('parent dicarder called with id ' + id);
        getClient().delete('/api/client/' + client.memberId + '/goal/' + id)
            .then((response) => {
                updateGoals();
                ReactGA.event({
                    category: 'Goal Editing',
                    action: 'Discarded Goal'
                });
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not delete goal.', errorDetails: error.message}));
            });
    };

    const markGoalAsAchieved = (id) => {
        console.log('parent achiever called with id ' + id);
        getClient().get('/api/client/' + client.memberId + '/goal/' + id + '/achieved', )
            .then((response) => {
                updateGoals();
                ReactGA.event({
                    category: 'Goal Editing',
                    action: 'Goal Marked as Achieved'
                });
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not delete goal.', errorDetails: error.message}));
            });
    };

    const openAddGoalDialog = () => {
        setAddGoalDialogIsOpen(true);
    };

    const closeAddGoalDialog = () => {
        setAddGoalDialogIsOpen(false);
    };

    const handleTargetDateChange = (date) => {
        setTargetDate(date);
    };

    const finishGoalMaintenance = () => {
        dispatch(setNavigationToClientDashboardPage());
    };

    return (
        <div>
            <Container>
                <Box m={2}>
                    <Typography component="div" variant="h5" color="secondary" align="center" style={{paddingBottom: 10}}>
                        Goal Maintenance: {client.name}
                    </Typography>
                    {goals.map(goal => (
                        <GoalEditor key={goal.id} goal={goal} goalAchiever={markGoalAsAchieved} goalDiscarder={discardGoal}/>
                    ))}
                </Box>
                <Grid container justify="center" style={{paddingTop: 20, paddingBottom: 20}}>
                    <Box component="span" m={0.5}>
                        <Button variant="contained" onClick={openAddGoalDialog}>Add a New Goal</Button>
                    </Box>
                    <Box component="span" m={0.5}>
                        <Button variant="contained" color="secondary" onClick={finishGoalMaintenance}>Finished</Button>
                    </Box>
                </Grid>
            </Container>
            <Dialog open={addGoalDialogIsOpen} aria-labelledby="add-goal-dialog" fullWidth maxWidth="lg">
                <form onSubmit={handleSubmit(addGoal)}>
                    <DialogTitle id="add-goal-dialog">Add New Goal</DialogTitle>
                    <DialogContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={9}>
                                    <FormControl fullWidth>
                                        <TextField
                                            error={!!errors.description}
                                            name='description'
                                            inputRef={register({required: true})}
                                            id='description'
                                            label="Description"
                                            inputProps={{
                                                maxLength: 100
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                            <KeyboardDatePicker
                                                error={!!errors.targetDate}
                                                id='targetDate'
                                                name='targetDate'
                                                inputRef={register({ required: true })}
                                                label="* Target Date"
                                                format="MM/dd/yyyy"
                                                value={targetDate}
                                                onChange={handleTargetDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                            </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeAddGoalDialog} variant="contained">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" variant="contained">
                            Add
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>

    )
}