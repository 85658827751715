import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function Goal(props) {

    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    // Since we're dealing with a form array, checking field validity is a little more complex than the typical statements
    // that are inlined, hence this function.
    const goalFieldIsValid = (id, name) => {
        if (props.errors.goals && props.errors.goals[id]) {
            return props.errors.goals[id][name];
        } else {
            return false;
        }
    };

    return (
        <div>
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h6" component="h2">
                        Goal #{props.goal.id + 1}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={9}>
                            <FormControl fullWidth>
                                <TextField
                                    error={!!goalFieldIsValid(props.goal.id, 'description')}
                                    name={'goals[' + props.goal.id + '][description]'}
                                    inputRef={props.register({required: true})}
                                    id={'goals[' + props.goal.id + '][description]'}
                                    label="* Description"
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        error={!!goalFieldIsValid(props.goal.id, 'targetDate')}
                                        id={'goals[' + props.goal.id + '][targetDate]'}
                                        name={'goals[' + props.goal.id + '][targetDate]'}
                                        inputRef={props.register({ required: true })}
                                        label="* Target Date"
                                        format="MM/dd/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )

}