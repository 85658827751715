import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import PhoneNumberInput from "./PhoneNumberInput";
import EmailAddressInput from "./EmailAddressInput";

export default function MedicalContact(props) {
    return (
        <div>
            <Grid container spacing={2} justify="center" style={{paddingTop: 40}}>
                <Grid item xs={12}>
                    <Typography component="div" variant="h5" color="secondary">{props.label}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'FirstName']}
                                   name={props.prefix + 'FirstName'}
                                   inputRef={props.register({required: false})}
                                   id={props.prefix + 'FirstName'}
                                   label="First Name"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'LastName']}
                                   name={props.prefix + 'LastName'}
                                   inputRef={props.register({required: false})}
                                   id={props.prefix + 'LastName'}
                                   label="Last Name"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <PhoneNumberInput {...props}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <EmailAddressInput {...props}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
}