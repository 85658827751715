import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export default function MembershipInfo(props) {

    const membershipTypes = ['School', 'Open Gym',  'Adult Day Group', '30 Minute Private', '60 Minute Private'];

    const [startDate, setStartDate] = React.useState(new Date());

    const handleStartDateChange = date => {
        setStartDate(date);
    };

    return(
        <div>
            <Grid container spacing={2} justify="center" style={{paddingTop: 40}}>
                <Grid item xs={12}>
                    <Typography component="div" variant="h5" color="secondary">Membership Information</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.referredBy}
                                   name="referredBy"
                                   inputRef={props.register({required: false})}
                                   id="referredBy"
                                   label="Referred By"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl error={!!props.errors.membershipType} fullWidth>
                        <InputLabel htmlFor="membershipType">* Membership Type</InputLabel>
                        <Select
                            native
                            inputRef={props.register({ required: true })}
                            label="Membership Type"
                            inputProps={{
                                name: 'membershipType',
                                id: 'membershipType',
                            }}
                        >
                            <option aria-label="Select" value="" />
                            {membershipTypes.map((item, key) => (
                                <option key={key} value={item}>{item}</option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            id="membershipStartDate"
                            name="membershipStartDate"
                            inputRef={props.register({ required: true })}
                            label="* Membership Start Date"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </div>
    )

}