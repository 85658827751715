import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PreviousEnrollmentStepButton from "./PreviousEnrollmentStepButton";
import DiscardEnrollmentButton from "./DiscardEnrollmentButton";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function EnrollmentNavigationButtons(props) {
    return (
        <Grid container justify="center" style={{paddingTop: 40, paddingBottom: 20}}>
            {!props.isFirstStep ?
                <Box component="span" m={0.5}>
                    <PreviousEnrollmentStepButton/>
                </Box>
            : ''}
            <Box component="span" m={0.5}>
                <DiscardEnrollmentButton/>
            </Box>
            <Box component="span" m={0.5}>
                <Button type="submit" variant="contained" color="secondary" endIcon={<ArrowForwardIosIcon/>}>
                    Next
                </Button>
            </Box>
        </Grid>
    )
}