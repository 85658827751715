import React from 'react';
import Label from "./Label";
import ExercisePrompt from "./ExercisePrompt";

export default function ExercisePrompts(props) {

    const exercise = props.exercise;

    return (
        <div>
            <Label label="Prompts: "/>
            <ExercisePrompt value={exercise.promptLevel} label="Level"/>
            <ExercisePrompt value={exercise.promptFaded} label="Faded"/>
        </div>
    );
}