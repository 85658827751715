import React from 'react';
import {Controller} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";

export default function PhysicalProfile(props) {

    const medicalDiagnoses = ['Autism', 'Down Syndrome', 'PraderWilli', 'CP', 'TBI', 'DD', 'Amputee', 'Spina Bifida', 'Epilepsy', 'Depression', 'Personality Disorder', 'Bipolar Disorder', 'Muscular Dystrophy', 'Rhumatoid Arthritis', 'ADHD'];
    const heightFeetOptions = [2, 3, 4, 5, 6, 7, 8];
    const heightInchesOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    const [dateOfBirth, setDateOfBirth] = React.useState(null);
    const [medicalDiagnosisOtherIsChecked, setMedicalDiagnosisOtherIsChecked] = React.useState(false);

    const handleDateOfBirthChange = date => {
        setDateOfBirth(date);
    };

    const handleMedicalDiagnosisOtherChecked = (event) => {
        setMedicalDiagnosisOtherIsChecked(event.target.checked);
    };

    return (
        <section>
            <Box pt={2} maxWidth={800} mx="auto">
                <Grid container spacing={3} justify="flex-start">

                    <Grid item xs={12}>
                        <Typography component="div" variant="h5" color="secondary">Physical Profile</Typography>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <FormControl error={!!props.errors.gender} component="fieldset">
                            <FormLabel component="legend">* Gender</FormLabel>
                            <Controller
                                as={<RadioGroup
                                    row
                                    aria-label="gender"
                                >
                                    <FormControlLabel value={"Male"} control={<Radio />} label="Male" />
                                    <FormControlLabel value={"Female"} control={<Radio />} label="Female" />
                                </RadioGroup>}

                                name="gender"
                                defaultValue={""}
                                control={props.control}
                                rules={{ required: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl error={!!props.errors.heightFeet} fullWidth>
                            <InputLabel htmlFor="numberOfTimesTrainerAskedClientToLookAtSchedule">* Height (ft)</InputLabel>
                            <Select
                                native
                                inputRef={props.register({ required: true })}
                                label="Height (ft)"
                                inputProps={{
                                    name: 'heightFeet',
                                    id: 'heightFeet',
                                }}
                            >
                                <option aria-label="Select" value="" />
                                {heightFeetOptions.map((item, key) => (
                                    <option key={key} value={item}>{item}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl error={!!props.errors.heightInches} fullWidth>
                            <InputLabel htmlFor="numberOfTimesTrainerAskedClientToLookAtSchedule">* Height (in)</InputLabel>
                            <Select
                                native
                                inputRef={props.register({ required: true })}
                                label="Height (in)"
                                inputProps={{
                                    name: 'heightInches',
                                    id: 'heightInches',
                                }}
                            >
                                <option aria-label="Select" value="" />
                                {heightInchesOptions.map((item, key) => (
                                    <option key={key} value={item}>{item}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.weight}
                                       name="weight"
                                       type="tel"
                                       inputRef={props.register({required: true, pattern: /^[1-9][0-9]{1,2}$/})} // two or three digit number, first number non-zero
                                       id="weight"
                                       label="* Weight (lbs)"
                                       inputProps={{
                                           maxLength: 3
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker
                                id="dateOfBirth"
                                name="dateOfBirth"
                                error={!!props.errors.dateOfBirth}
                                inputRef={props.register({ required: true })}
                                label="* Date of Birth"
                                format="MM/dd/yyyy"
                                value={dateOfBirth}
                                onChange={handleDateOfBirthChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.foodAllergies}
                                       name="foodAllergies"
                                       type="text"
                                       inputRef={props.register}
                                       id="foodAllergies"
                                       label="Food Allergies"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.drugAllergies}
                                       name="drugAllergies"
                                       type="text"
                                       inputRef={props.register}
                                       id="drugAllergies"
                                       label="Drug Allergies"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.materialAllergies}
                                       name="materialAllergies"
                                       type="text"
                                       inputRef={props.register}
                                       id="materialAllergies"
                                       label="Material Allergies"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.environmentalAllergies}
                                       name="environmentalAllergies"
                                       type="text"
                                       inputRef={props.register}
                                       id="environmentalAllergies"
                                       label="Environmental Allergies"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.insectAllergies}
                                       name="insectAllergies"
                                       type="text"
                                       inputRef={props.register}
                                       id="insectAllergies"
                                       label="Insect Allergies"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.physicalOrNeurologicalChallenges}
                                       name="physicalOrNeurologicalChallenges"
                                       type="text"
                                       inputRef={props.register}
                                       id="physicalOrNeurologicalChallenges"
                                       label="Physical/Neurological Challenges"
                                       inputProps={{
                                           maxLength: 100
                                       }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid container spacing={3} justify="center" style={{padding:25}}>
                        <Box p={2} border={1} borderRadius={10} borderColor="grey.500">
                            <Grid item xs={12}>
                                <FormControl fullWidth component="fieldset" error={false}>
                                    <FormLabel component="legend">Medical Diagnoses</FormLabel>
                                    <FormGroup row>
                                        {medicalDiagnoses.map((medicalDiagnosis, key) => {
                                            const formName = 'medicalDiagnosis' + medicalDiagnosis.replace(/\s+/g, '');
                                            return (
                                                <FormControlLabel key={key}
                                                    control={<Checkbox name={formName} />}
                                                    label={medicalDiagnosis}
                                                    inputRef={props.register}
                                                />
                                                )
                                        })}
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleMedicalDiagnosisOtherChecked} name="medicalDiagnosisOther" />}
                                            label="Other"
                                            inputRef={props.register}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            {medicalDiagnosisOtherIsChecked ?
                                <Box py={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField name="medicalDiagnosisOtherDetails"
                                                       variant="outlined"
                                                       multiline
                                                       inputRef={props.register}
                                                       id="medicalDiagnosisOther"
                                                       label="Other Medical Diagnoses"
                                                       inputProps={{
                                                           maxLength: 200
                                                       }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Box>
                                : ''}
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField error={!!props.errors.additionalNotes}
                                       name="additionalNotes"
                                       type="text"
                                       multiline
                                       inputRef={props.register}
                                       id="additionalNotes"
                                       label="Additional Notes"
                                       inputProps={{
                                           maxLength: 2500
                                       }}
                            />
                        </FormControl>
                    </Grid>

                </Grid>
            </Box>
        </section>
    )

}