import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    bold: {
        // fontWeight: 500,
        fontSize: "medium"
    }
})

export default function Label(props) {

    const classes = useStyles();

    return (
        <Typography component="span" color="secondary" className={classes.bold}>{props.label}</Typography>
    );
}