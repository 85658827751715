import React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {CardHeader} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function GoalEditor(props) {

    const [achieveGoalConfirmationDialogIsOpen, setAchieveGoalConfirmationDialogIsOpen] = React.useState(false);
    const [deleteGoalConfirmationDialogIsOpen, setDeleteGoalConfirmationDialogIsOpen] = React.useState(false);

    const openAchieveGoalConfirmationDialog = () => {
        setAchieveGoalConfirmationDialogIsOpen(true);
    };

    const closeAchieveGoalConfirmationDialog = () => {
        setAchieveGoalConfirmationDialogIsOpen(false);
    };

    const openDeleteGoalConfirmationDialog = () => {
        setDeleteGoalConfirmationDialogIsOpen(true);
    };

    const closeDeleteGoalConfirmationDialog = () => {
        setDeleteGoalConfirmationDialogIsOpen(false);
    };

    const markGoalAsAchieved = () => {
        props.goalAchiever(props.goal.id);
        closeAchieveGoalConfirmationDialog();
    };

    const deleteGoal = () => {
        props.goalDiscarder(props.goal.id);
        closeDeleteGoalConfirmationDialog();
    };

    const getCheckAvatarIfAchieved = () => {
        if (props.goal.achievedDate) {
            return <Avatar style={{backgroundColor: "green"}}><CheckIcon /></Avatar>;
        } else {
            return <Avatar><RadioButtonUncheckedIcon /></Avatar>;
        }
    };

    return (
        <div>
            <Box mb={3} maxWidth={1200} mx="auto">
                <Card>
                    <CardHeader
                        avatar={getCheckAvatarIfAchieved()}
                        title={'Goal: ' + props.goal.description}>
                        <CheckIcon/>
                    </CardHeader>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Target Date: {props.goal.targetDate}
                        </Typography>
                        {props.goal.achievedDate ?
                            <Typography variant="body2" color="textSecondary" component="p">
                                Achieved Date: {props.goal.achievedDate}
                            </Typography>
                            : ''}
                    </CardContent>
                    {!props.goal.achievedDate ?
                        <CardActions >
                            <Button variant="contained" onClick={openDeleteGoalConfirmationDialog} endIcon={<DeleteIcon/>}>Discard</Button>
                            <Button style={{marginLeft: "auto"}} variant="contained" color="primary" endIcon={<CheckIcon/>} onClick={openAchieveGoalConfirmationDialog}>Achieved</Button>
                        </CardActions>
                        : ''}
                </Card>
            </Box>
            <Dialog
                open={achieveGoalConfirmationDialogIsOpen}
                aria-labelledby="achieve-goal-alert-dialog-title"
                aria-describedby="achieve-goal-alert-dialog-description"
            >
                <DialogTitle id="achieve-goal-alert-dialog-title">{"Mark as Achieved?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="achieve-goal-alert-dialog-description">
                        <div>
                            Please confirm that the client achieved the following goal:
                        </div>
                        <div style={{paddingTop: 20}}>
                            {props.goal.description}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAchieveGoalConfirmationDialog} color="secondary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={markGoalAsAchieved}>
                        Yes, Mark as Achieved
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteGoalConfirmationDialogIsOpen}
                aria-labelledby="delete-goal-alert-dialog-title"
                aria-describedby="delete-goal-alert-dialog-description"
            >
                <DialogTitle id="delete-goal-alert-dialog-title">{"Discard Goal: Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-goal-alert-dialog-description">
                        <div>
                            Please confirm that you want to delete the following goal:
                        </div>
                        <div style={{paddingTop: 20}}>
                            {props.goal.description}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteGoalConfirmationDialog} color="secondary" autoFocus>
                        No, Keep It
                    </Button>
                    <Button onClick={deleteGoal}>
                        Yes, Delete It
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}