import React from "react";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import Box from "@material-ui/core/Box";
import {storeEnrollmentPhysicalActivityQuestionnaire} from "../../../reducers/enrollmentDataSlice";
import {nextEnrollmentStep} from "../../../reducers/enrollmentStepSlice";
import EnrollmentNavigationButtons from "../buttons/EnrollmentNavigationButtons";
import OccupationalQuestions from "./OccupationalQuestions";

import RecreationalQuestions from "./RecreationalQuestions";
import MedicalQuestions from "./MedicalQuestions";

export default function PhysicalActivitySection(props) {

    const {control, register, errors, watch, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const onSubmit = data => {
        dispatch(storeEnrollmentPhysicalActivityQuestionnaire(data));
        dispatch(nextEnrollmentStep());
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <section style={!props.show ? {display: "none"} : {}}>
                <Box mx="auto" maxWidth={800} p={3}>
                    <OccupationalQuestions errors={errors} register={register} control={control} watch={watch} />
                    <RecreationalQuestions errors={errors} register={register} control={control} watch={watch} />
                    <MedicalQuestions errors={errors} register={register} control={control} watch={watch} />
                    <EnrollmentNavigationButtons/>
                </Box>
            </section>
        </form>
    )
}