import React from 'react';
import ReactGA from 'react-ga';
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ClientDetails from "./clientdetails/ClientDetails";
import EquipmentOverview from "../client/EquipmentOverview";

export default function WorkoutDrawer() {

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const openDrawer = () => {
        setDrawerOpen(true);
        ReactGA.event({
            category: 'Client',
            action: 'View Details'
        });
    };

    const closeDrawer = () => {
        setDrawerOpen(false);
    };

  return(
      <div>
        <IconButton edge="end" color="inherit" onClick={openDrawer}>
            <ArrowForwardIosIcon/>
        </IconButton>
        <Drawer open={drawerOpen} onClose={closeDrawer}>
            <Box>
                <ClientDetails/>
            </Box>
            <Box>
                <EquipmentOverview/>
            </Box>
            <Box my={3} mx="auto">
                <Button variant="outlined" onClick={closeDrawer}>Hide</Button>
            </Box>
        </Drawer>
      </div>
  )
};