import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {Controller} from "react-hook-form";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TextField} from "@material-ui/core";

export default function OccupationalQuestions(props) {

    const watchInjuriesOrChronicPainAnswer = props.watch('injuriesOrChronicPain', '');
    const watchSurgeriesAnswer = props.watch('surgeries', '');
    const watchChronicDiseaseAnswer = props.watch('chronicDisease', '');
    const watchMedicationsAnswer = props.watch('medications', '');

    return (
        <Grid container spacing={3} justify="flex-start" style={{paddingTop: 20}}>

            <Grid item xs={12}>
                <Typography component="div" variant="h5" color="secondary">Medical Questions</Typography>
            </Grid>

            <Grid item xs={12}>
                <FormControl error={!!props.errors.injuriesOrChronicPain} component="fieldset">
                    <FormLabel component="legend">* Have you ever had any injuries or chronic pain?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="injuriesOrChronicPain"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="injuriesOrChronicPain"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchInjuriesOrChronicPainAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.injuriesOrChronicPainDescription}
                                   name="injuriesOrChronicPainDescription"
                                   inputRef={props.register({required: true})}
                                   id="injuriesOrChronicPainDescription"
                                   label="* Description of Injuries or Chronic Pain"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

            <Grid item xs={12}>
                <FormControl error={!!props.errors.surgeries} component="fieldset">
                    <FormLabel component="legend">* Have you ever had any surgeries?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="surgeries"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="surgeries"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchSurgeriesAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.surgeriesDescription}
                                   name="surgeriesDescription"
                                   inputRef={props.register({required: true})}
                                   id="surgeriesDescription"
                                   label="* Description of Surgeries"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

            <Grid item xs={12}>
                <FormControl error={!!props.errors.chronicDisease} component="fieldset">
                    <FormLabel component="legend">* Has a medical doctor ever diagnosed you with a chronic disease, such as coronary heart disease, coronary artery disease, hypertension (high blood pressure), high cholesterol or diabetes?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="chronicDisease"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="chronicDisease"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchChronicDiseaseAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.chronicDiseaseDescription}
                                   name="chronicDiseaseDescription"
                                   inputRef={props.register({required: true})}
                                   id="chronicDiseaseDescription"
                                   label="* List of Chronic Diseases"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

            <Grid item xs={12}>
                <FormControl error={!!props.errors.medications} component="fieldset">
                    <FormLabel component="legend">* Are you currently taking any medications?</FormLabel>
                    <Controller
                        as={<RadioGroup
                            row
                            aria-label="medications"
                        >
                            <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
                            <FormControlLabel value={"No"} control={<Radio />} label="No" />
                        </RadioGroup>}

                        name="medications"
                        defaultValue={""}
                        control={props.control}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Grid>

            {watchMedicationsAnswer === 'Yes' ?
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors.medicationsDescription}
                                   name="medicationsDescription"
                                   inputRef={props.register({required: true})}
                                   id="medicationsDescription"
                                   label="* List of Medications"
                                   variant="outlined"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                : ''}

        </Grid>
    )
}