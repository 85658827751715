import React from 'react';
import {useDispatch} from "react-redux";
import {Controller, useForm} from 'react-hook-form'
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MoodPicker from "./MoodPicker";
import Button from "@material-ui/core/Button";
import {nextWorkoutStep} from "../../reducers/workoutStepSlice";
import DiscardWorkoutButton from "./buttons/DiscardWorkoutButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import {storePreWorkoutData} from "../../reducers/preWorkoutDataSlice";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function PreWorkoutQuestions(props) {

    const {control, register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = React.useState(new Date());

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const onSubmit = data => {
        console.log('result of post for pre workout questions:', data);
        dispatch(storePreWorkoutData(data));
        dispatch(nextWorkoutStep());
    };

    return (
        <div style={!props.show ? {display: "none"} : {}}>
            <Container>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box pt={2} maxWidth={800} mx="auto">
                        <Grid container spacing={3} justify="center">
                            <Grid item xs={12} sm={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        name="date"
                                        inputRef={register({ required: true })}
                                        label="* Date of Training"
                                        format="MM/dd/yyyy"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <MoodPicker register={register} errors={errors}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={!!errors.trainingType} component="fieldset">
                                    <FormLabel component="legend">* Training Type</FormLabel>
                                    <Controller
                                        as={<RadioGroup
                                            row
                                            aria-label="trainingType"
                                        >
                                            <FormControlLabel value={"Individual"} control={<Radio />} label="Individual" />
                                            <FormControlLabel value={"Group"} control={<Radio />} label="Group" />
                                            <FormControlLabel value={"Open Gym"} control={<Radio />} label="Open Gym" />
                                        </RadioGroup>}

                                        name="trainingType"
                                        defaultValue={"Individual"}
                                        control={control}
                                        rules={{ required: true }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl error={!!errors.trainingDuration} component="fieldset">
                                    <FormLabel component="legend">* Training Duration</FormLabel>
                                    <Controller
                                        as={<RadioGroup
                                            row
                                            aria-label="trainingDuration"
                                        >
                                            <FormControlLabel value={"30 minutes"} control={<Radio />} label="30 minutes" />
                                            <FormControlLabel value={"45 minutes"} control={<Radio />} label="45 minutes" />
                                            <FormControlLabel value={"60 minutes"} control={<Radio />} label="60 minutes" />
                                        </RadioGroup>}

                                        name="trainingDuration"
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: true }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container justify="center" style={{padding: 25}}>
                        <Box component="span" m={0.5}>
                            <DiscardWorkoutButton/>
                        </Box>
                        <Box component="span" m={0.5}>
                            <Button type="submit" variant="contained" color="secondary" endIcon={<ArrowForwardIosIcon/>}>
                                Next
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </Container>
        </div>
    );
};