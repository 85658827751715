import React from 'react';
import Exercise from ".//Exercise";
import Label from "./Label";
import Grid from "@material-ui/core/Grid";

export default function Exercises(props) {
    return (
        <div>
            {props.exercises.length > 0 &&
                <span>
                    <Label label="Exercises: "/>
                    <Grid container spacing={2}>
                        {props.exercises.map((exercise, key) => (
                            <Grid item xs={12} md={6} lg={4}>
                                <Exercise key={key} exercise={exercise}/>
                            </Grid>
                        ))}
                    </Grid>
                </span>
            }
        </div>
    );
}