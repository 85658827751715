import React from 'react';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {getClient} from "../../api/api";
import {useDispatch} from "react-redux";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";

export default function Logi() {

    const dispatch = useDispatch();

    const handleLogiLogin = () => {
        getClient().post('/api/logi/admin/login')
            .then((response) => {
                window.open(response.data, '_blank');
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not log into Logi admin reporting system.', errorDetails: error.message}));
            });
    };

    return (
        <Card raised={true}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Logi Reports
                </Typography>
                <Typography component="div">
                    Administrators can use Logi reports for information about site-wide client information.
                </Typography>
            </CardContent>
            <CardActions>
                <Box mx="auto">
                    <Button variant="contained" color="primary" onClick={handleLogiLogin}>Open Logi Reports</Button>
                </Box>
            </CardActions>
        </Card>
    );
}