import React from 'react';
import Label from "./Label";

export default function LabelAndValue(props) {

    const shouldDisplay = () => {
        return props.value;
    }

    return (
        <div>
            {shouldDisplay()  &&
                <span>
                    <Label label={props.label} />
                    <span>{props.value}</span>
                </span>
            }
        </div>
    );
}