import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import PhoneNumberInput from "./PhoneNumberInput";
import EmailAddressInput from "./EmailAddressInput";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {useSelector} from "react-redux";

export default function PrimaryContact(props) {

    const client = useSelector(store => store.client);

    return (
        <div>
            <Grid container spacing={2} justify="center" style={{paddingTop: 40}}>
                <Grid item xs={12}>
                    <Typography component="div" variant="h5" color="secondary">{props.label} Contact Info</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'FirstName']}
                                   name={props.prefix + 'FirstName'}
                                   inputRef={props.register({required: props.nameRequired})}
                                   id={props.prefix + 'FirstName'}
                                   label={(props.nameRequired ? '* ' : '') + "First Name"}
                                   inputProps={{
                                       maxLength: 100
                                   }}
                                   value={client.firstName}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'LastName']}
                                   name={props.prefix + 'LastName'}
                                   inputRef={props.register({required: props.nameRequired})}
                                   id={props.prefix + 'LastName'}
                                   label={(props.nameRequired ? '* ' : '') + "Last Name"}
                                   inputProps={{
                                       maxLength: 100
                                   }}
                                   value={client.lastName}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'Address']}
                                   name={props.prefix + 'Address'}
                                   inputRef={props.register({required: false})}
                                   id={props.prefix + 'Address'}
                                   label="Address"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'City']}
                                   name={props.prefix + 'City'}
                                   inputRef={props.register({required: false})}
                                   id={props.prefix + 'City'}
                                   label="City"
                                   inputProps={{
                                       maxLength: 100
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={2}>
                    <FormControl error={!!props.errors.state} fullWidth>
                        <InputLabel htmlFor="state">State</InputLabel>
                        <Select
                            native
                            inputRef={props.register({ required: false })}
                            label="State"
                            inputProps={{
                                name: props.prefix + 'State',
                                id: props.prefix + 'State',
                            }}
                        >
                            <option aria-label="Select" value="" />
                            {props.states.map((item, key) => (
                                <option key={key} value={item.abbreviation}>{item.abbreviation}</option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControl fullWidth>
                        <TextField error={!!props.errors[props.prefix + 'Zip']}
                                   name={props.prefix + 'Zip'}
                                   type="tel"
                                   inputRef={props.register({required: false, pattern: /^[0-9]{5}(-[0-9]{4})?$/})}
                                   id={props.prefix + 'Zip'}
                                   label="Zip"
                                   inputProps={{
                                       maxLength: 10
                                   }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <PhoneNumberInput {...props}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <EmailAddressInput {...props} currentAddress={client.email}/>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
};