import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrown, faFrownOpen, faMeh, faSmile, faSmileBeam} from "@fortawesome/free-solid-svg-icons";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

const useStyles = makeStyles({
    text: {
        fontSize: "5vw"
    },
    smiley: {
        fontSize: "12vw"
    },
    smileyText: {
        fontSize: "2vw"
    }
});

export default function MoodPicker(props) {

    const moods = ['Awful', 'Not Very Good', 'Okay', 'Really Good', 'Fantastic'];

    const classes = useStyles();

    const [mood, setMood] = React.useState('');
    const [moodPickerIsDialogOpen, setMoodPickerDialogIsOpen] = React.useState(false);

    const handleOpenMoodPickerDialog = () => {
        setMoodPickerDialogIsOpen(true);
    };

    const handleCloseMoodPickerDialog = () => {
        setMoodPickerDialogIsOpen(false);
    };

    const handleMoodChangeFromMoodPicker = newMood => () => {
        setMood(newMood);
    };

    const handleMoodChangeFromSelect = (event) => {
        setMood(event.target.value);
    };

    return(
        <div>
            <Grid container spacing={0} justify="center">
                <Grid item xs={10}>
                    <Box textAlign="center">
                        <FormControl error={!!props.errors.mood} fullWidth style={{paddingRight: 10}}>
                            <InputLabel htmlFor="mood">* Mood</InputLabel>
                            <Select
                                native
                                inputRef={props.register({ required: true })}
                                label='Mood'
                                value={mood}
                                onChange={handleMoodChangeFromSelect}
                                inputProps={{
                                    name: 'mood',
                                    id: 'mood',
                                }}
                            >
                                <option aria-label="Select" value="" />
                                {moods.map((mood) => (
                                    <option key={mood} value={mood}>{mood}</option>
                                ))}
                            </Select>
                        </FormControl>

                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Button variant={"contained"} onClick={handleOpenMoodPickerDialog} fullWidth={true} style={{marginTop: 10}}>
                        <InsertEmoticonIcon />
                    </Button>
                </Grid>
            </Grid>
            <Dialog open={moodPickerIsDialogOpen} onClose={handleCloseMoodPickerDialog} aria-labelledby="choose-mood-dialog"  maxWidth="lg" >
                <Box my={4}>
                    <Typography component="div" variant="h3" align={"center"} className={classes.text}>
                        How is your mood?
                    </Typography>
                </Box>
                <DialogContent>
                    <Box textAlign="center">
                        <Grid container spacing={0} justify="center">
                            <Grid item xs={2.2}>
                                <IconButton aria-label={moods[0].description} onClick={handleMoodChangeFromMoodPicker(moods[0])}>
                                    <FontAwesomeIcon icon={faFrown} color="#e62e2d" className={classes.smiley}/>
                                </IconButton>
                                <Typography component="div" variant="h3" align={"center"} className={classes.smileyText}>
                                    {moods[0]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.2}>
                                <IconButton aria-label={moods[1].description} onClick={handleMoodChangeFromMoodPicker(moods[1])}>
                                    <FontAwesomeIcon icon={faFrownOpen} color="#ee9e1e" className={classes.smiley}/>
                                </IconButton>
                                <Typography component="div" variant="h3" align={"center"} className={classes.smileyText}>
                                    {moods[1]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.2}>
                                <IconButton aria-label={moods[2].description} onClick={handleMoodChangeFromMoodPicker(moods[2])}>
                                    <FontAwesomeIcon icon={faMeh} color="#f7dd06" className={classes.smiley}/>
                                </IconButton>
                                <Typography component="div" variant="h3" align={"center"} className={classes.smileyText}>
                                    {moods[2]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.2}>
                                <IconButton aria-label={moods[3].description} onClick={handleMoodChangeFromMoodPicker(moods[3])}>
                                    <FontAwesomeIcon icon={faSmile} color="#ced404" className={classes.smiley}/>
                                </IconButton>
                                <Typography component="div" variant="h3" align={"center"} className={classes.smileyText}>
                                    {moods[3]}
                                </Typography>
                            </Grid>
                            <Grid item xs={2.2}>
                                <IconButton aria-label={moods[4].description} onClick={handleMoodChangeFromMoodPicker(moods[4])}>
                                    <FontAwesomeIcon icon={faSmileBeam} color="#8cd101" className={classes.smiley}/>
                                </IconButton>
                                <Typography component="div" variant="h3" align={"center"} className={classes.smileyText}>
                                    {moods[4]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box m={4} mt={8}>
                        <Typography component="div" variant="h3" align={"center"} className={classes.text}>
                            I feel: {mood}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMoodPickerDialog} color="secondary" variant="contained">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};