import {createSlice} from '@reduxjs/toolkit'

const workoutStepSlice = createSlice({
    name: 'workoutStep',
    initialState: 0,
    reducers: {
        nextWorkoutStep(state) {
            return state + 1;
        },
        previousWorkoutStep(state) {
            return state - 1;
        },
        resetWorkoutStep() {
            return 0;
        }
    }
});

export const {nextWorkoutStep, previousWorkoutStep, resetWorkoutStep} = workoutStepSlice.actions;

export default workoutStepSlice.reducer