import React from 'react';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {setNavigationToClientDashboardPage} from "../../../reducers/navigateSlice";
import {useDispatch} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DeleteIcon from '@material-ui/icons/Delete';
import {removeClient} from "../../../reducers/clientSlice";

export default function DiscardEnrollmentButton() {

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const discardEnrollment = () => {
        dispatch(setNavigationToClientDashboardPage());
    };

    return (
        <div>
            <Box component="span" m={0.5}>
                <Button variant="contained" onClick={handleClickOpen} endIcon={<DeleteIcon/>}>
                    Discard Enrollment
                </Button>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="discard-enrollment-alert-dialog-title"
                aria-describedby="discard-enrollment-alert-dialog-description"
            >
                <DialogTitle id="discard-enrollment-alert-dialog-title">{"Discard Enrollment: Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="enrollment-workout-alert-dialog-description">
                        Are you sure you want to discard this enrollment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" autoFocus>
                        No, Keep It
                    </Button>
                    <Button onClick={discardEnrollment}>
                        Yes, Discard It
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )
};