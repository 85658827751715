import React from 'react';
import Label from "./Label";
import ExercisePrompt from "./ExercisePrompt";
import Box from "@material-ui/core/Box";

export default function ExerciseEquipment(props) {

    const exercise = props.exercise;

    const determineLabel = () => {
        if (props.label) {
            return props.label;
        } else {
            let label = exercise.exerciseName;
            if (exercise.exerciseType) {
                label += ' (' + exercise.exerciseType + ')';
            }
            const formattedDate = exercise.dateOfEntry.replace(/T.*/, '');
            label += ' ' + formattedDate + ': ';
            return label;
        }
    }

    return (
        <Box my={props.my}>
            <Label label={determineLabel()}/>
            <ExercisePrompt value={exercise.height} label="Height"/>
            <ExercisePrompt value={exercise.weight} label="Weight"/>
            <ExercisePrompt value={exercise.time} label="Time"/>
            <ExercisePrompt value={exercise.timeunits} label="Time Units"/>
            <ExercisePrompt value={exercise.load} label="Load"/>
            <ExercisePrompt value={exercise.resistanceBand} label="Resistance Band"/>
            <ExercisePrompt value={exercise.progression} label="Progression"/>
            <ExercisePrompt value={exercise.equipment} label="Equipment"/>
            <ExercisePrompt value={exercise.distance} label="Distance"/>
            <ExercisePrompt value={exercise.speed} label="Speed"/>
            <ExercisePrompt value={exercise.reps} label="Reps"/>
            <ExercisePrompt value={exercise.sets} label="Sets"/>
        </Box>
    );
}