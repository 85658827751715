import React from 'react';
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export default function EmailAddressInput(props) {

    const postFix = 'Email';

    return (
        <div>
            <FormControl fullWidth>
                <TextField error={!!props.errors[props.prefix + postFix]}
                           name={props.prefix + postFix}
                           type="email"
                           inputRef={props.register({required: props.emailRequired, pattern: /^\S+@\S+$/i})}
                           id={props.prefix + postFix}
                           label={(props.emailRequired ? '* ' : '') + postFix}
                           inputProps={{
                               maxLength: 100
                           }}
                           value={props.currentAddress}
                />
            </FormControl>
        </div>
    )
}