import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {closeGlobalDialog} from "../reducers/globalDialogSlice";
import Navigate from "./Navigate";
import Box from "@material-ui/core/Box";

export default function AppContainer() {

    const dispatch = useDispatch();

    const globalDialog = useSelector(store => store.globalDialog);

    const handleCloseGlobalDialog = () => {
        dispatch(closeGlobalDialog());
    };

    return (
        <div>
            <Navigate/>
            <Dialog
                open={globalDialog.open}
                aria-labelledby="global-alert-dialog-title"
                aria-describedby="global-alert-dialog-description"
            >
                <DialogTitle id="global-alert-dialog-title">An Error Has Occurred</DialogTitle>
                <DialogContent>
                    <DialogContentText id="global-alert-dialog-description">
                        <div>
                            {globalDialog.errorMessage ? globalDialog.errorMessage : 'There was an error processing your request.'}
                        </div>
                        {globalDialog.errorDetails && (globalDialog.errorDetails.includes('timeout') || globalDialog.errorDetails.includes('Network Error')) ?
                            <Box fontWeight="fontWeightBold" my={2}>
                                Please ensure that your device is connected to the internet and try your request again.
                            </Box>
                            : ''}
                        <Box my={2}>
                            {globalDialog.errorDetails}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseGlobalDialog}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}