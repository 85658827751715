import React, {useEffect} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import Exercises from "./Exercises";
import {useSelector} from "react-redux";
import PostWorkoutQuestions from "./PostWorkoutQuestions";
import Box from "@material-ui/core/Box";
import PreWorkoutQuestions from "./PreWorkoutQuestions";

export default function Workout() {

    const workoutStep = useSelector(store => store.workoutStep);
    const client = useSelector(store => store.client);

    const steps = ['Pre-Workout Questions', 'Movements', 'Post-Workout Questions'];

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [workoutStep]);

    return (
        <div>
            <Box p={2} mx="auto">
                <Typography component="div" variant="h5" color="secondary" align="center">Workout: {client.name}</Typography>
            </Box>
            <Stepper activeStep={workoutStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <PreWorkoutQuestions show={workoutStep === 0}/>
                {workoutStep === 1 ? <Exercises/> : ''}
                <PostWorkoutQuestions show={workoutStep === 2}/>
            </div>
        </div>
    );
}