import React, {useEffect} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {getClient} from "../../../api/api";
import {useDispatch, useSelector} from "react-redux";
import ContactDetails from "./ContactDetails";
import {openGlobalDialog} from "../../../reducers/globalDialogSlice";
import Diagnoses from "./Diagnoses";
import Allergy from "./Allergy";

export default function ClientDetails() {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const [clientDetails, setClientDetails] = React.useState({});

    useEffect(() => {
        console.log('useEffect fired for loading client details');
        getClient().get('/api/client/' + client.memberId)
            .then((response) => {
                console.log(response.data);
                setClientDetails(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve client details.', errorDetails: error.message}));
            });
    }, [client.memberId, dispatch]);

    const formatText = text => {
        if (!text) {
            return 'N/A';
        } else {
            return text;
        }
    };

    return (
        <div>
            <Box m={3}>
                <Typography variant="h5" component="h2">
                    Client: {client.name}
                </Typography>

                {clientDetails.contactDetails ?
                    <Box my={2}>
                        <Typography component="div" variant="h6" color="secondary">
                            Contact Information
                        </Typography>
                        <ContactDetails label="Parent / Guardian" name={clientDetails.contactDetails.parentGuardianName} phone={clientDetails.contactDetails.parentGuardianPhone}/>
                        <ContactDetails label="Medical Contacts" name={clientDetails.contactDetails.medicalContactName} phone={clientDetails.contactDetails.medicalContactPhone}/>
                        <ContactDetails label="Caregiver" name={clientDetails.contactDetails.caregiverName} phone={clientDetails.contactDetails.caregiverPhone}/>
                    </Box>
                : ''}


                {clientDetails.physicalProfile ?
                    <Box my={2}>
                        <Typography component="div" variant="h6" color="secondary">
                            Physical Profile
                        </Typography>
                        <Typography component="div">
                            <strong>Medical Diagnoses:</strong> <Diagnoses physicalProfile={clientDetails.physicalProfile}/>
                        </Typography>
                        <Typography component="div">
                            <strong>Physical/Neurological Challenges:</strong> {formatText(clientDetails.physicalProfile.physicalOrNeurologicalChallenges)}
                        </Typography>
                        <Allergy label="Food Allergies" value={clientDetails.physicalProfile.foodAllergies}/>
                        <Allergy label="Drug Allergies" value={clientDetails.physicalProfile.drugAllergies}/>
                        <Allergy label="Material Allergies" value={clientDetails.physicalProfile.materialAllergies}/>
                        <Allergy label="Environmental Allergies" value={clientDetails.physicalProfile.environmentalAllergies}/>
                        <Allergy label="Insect Allergies" value={clientDetails.physicalProfile.insectAllergies}/>
                        <Typography component="div">
                            <strong>Notes:</strong> {formatText(clientDetails.physicalProfile.additionalNotes)}
                        </Typography>
                    </Box>
                : ''}

                {clientDetails.behaviorProfile ?
                    <Box my={2}>
                        <Typography component="div" variant="h6" color="secondary">
                            Behavior Profile
                        </Typography>
                        <Typography component="div">
                            <strong>Impacting Behaviors:</strong> {formatText(clientDetails.behaviorProfile.impactingBehaviors)}
                        </Typography>
                        <Typography component="div">
                            <strong>Behavior Strategies:</strong> {formatText(clientDetails.behaviorProfile.behaviorStrategies)}
                        </Typography>
                        <Typography component="div">
                            <strong>Music / Sensory Preferences:</strong> {formatText(clientDetails.behaviorProfile.musicOrSensoryPreferences)}
                        </Typography>
                        <Typography component="div">
                            <strong>Aggressive Behaviors:</strong> {formatText(clientDetails.behaviorProfile.aggressiveBehaviors)}
                        </Typography>
                    </Box>
                : ''}
            </Box>
        </div>
    )
}