import React, {useEffect} from 'react';
import ExerciseEquipment from "./ExerciseEquipment";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

export default function EquipmentOverview(props) {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const windowSize = 5;

    const [exercises, setExercises] = React.useState([]);
    const [visibleCount, setVisibleCount] = React.useState(windowSize);

    useEffect(() => {
        retrieveExercises();
    }, [dispatch]);

    const retrieveExercises = () => {
        getClient().get('/api/workout/' + client.memberId + '/exercises/')
            .then((response) => {
                setExercises(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve workout exercises.', errorDetails: error.message}));
            });
    }

    const showMore = () => {
        let newVisibleCount = visibleCount + windowSize;
        if (newVisibleCount > exercises.length) {
            newVisibleCount = exercises.length;
        }
        setVisibleCount(newVisibleCount);
    }

    const showAll = () => {
        setVisibleCount(exercises.length);
    }

    const shouldShowMoreButtonBeVisible = () => {
        return visibleCount < exercises.length;
    }

    return (
        <Card raised={props.raised} style={{height: '100%'}}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Equipment Overview
                </Typography>
                {exercises.length === 0 &&
                    <Typography>No equipment used yet.</Typography>
                }
                {exercises.map((exercise, index) => {
                    if (index < visibleCount) {
                        return (<ExerciseEquipment exercise={exercise} my={1}/>);
                    }
                })}
            </CardContent>
            <CardActions>
                {shouldShowMoreButtonBeVisible() &&
                    <Box mx="auto">
                        <Button variant="contained" color="secondary" onClick={showMore} style={{marginRight: 4}}>Show More</Button>
                        <Button variant="outlined" onClick={showAll}>Show All</Button>
                    </Box>
                }
            </CardActions>
        </Card>
    );
}