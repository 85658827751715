import {createSlice} from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {},
    reducers: {
        setUser(state, action) {
            console.log('setUser called', JSON.stringify(action.payload));
            return action.payload;
        }
    }
});

export const {setUser} = userSlice.actions;

export default userSlice.reducer