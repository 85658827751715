import React from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {setNavigationToLandingPage, setNavigationToAdminLandingPage} from "../../reducers/navigateSlice";
import Typography from "@material-ui/core/Typography";
import {setUser} from "../../reducers/userSlice";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";

export default function Login() {

    const {register, errors, handleSubmit} = useForm();
    const dispatch = useDispatch();

    const [failedLoginDialogIsOpen, setFailedLoginDialogIsOpen] = React.useState(false);
    const [requestInProgress, setRequestInProgress] = React.useState(false);

    const onSubmit = data => {
        console.log('authenticating with', JSON.stringify(data));

        setRequestInProgress(true);

        const username = data.username;
        const password = data.password;

        // Perform login validation request, do not use API wrapper in this case
        axios.post('/api/login', {
            username: username,
            password: password
        })
            .then((response) => {
                const loginResponse = response.data;
                if (loginResponse.success) {
                    // Store credentials
                    localStorage.setItem('username', username.toString());
                    localStorage.setItem('password', password.toString());

                    // Record event
                    ReactGA.event({
                        category: 'Authentication',
                        action: 'Logged in'
                    });

                    dispatch(setUser(loginResponse.user));
                    console.log(loginResponse.user);

                    // Look at response and see where user should be directed.
                    if (isAdminUser(loginResponse.user)) {
                        dispatch(setNavigationToAdminLandingPage());
                    } else {
                        dispatch(setNavigationToLandingPage());
                    }
                } else {
                    ReactGA.event({
                        category: 'Authentication',
                        action: 'Failed Login Attempt'
                    });

                    setFailedLoginDialogIsOpen(true);
                }
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorDetails: error.message}));
            })
            .then(() => {
                setRequestInProgress(false);
            });
    };

    const handleFailedLoginDialogClose = () => {
        setFailedLoginDialogIsOpen(false);
    };

    const isAdminUser = (user) => {
        return user.recordType === 85;
    }

    return (
        <Container>
            {window.location.href.includes('dev.') &&
                <Typography component="div" variant="h4" style={{background: "red", textTransform: "uppercase"}} align="center">This is the Development Site</Typography>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box pt={10} mx="auto" maxWidth={500}>
                    <Grid container spacing={3} justify="center">
                        <Grid item xs={12}>
                            <Typography component="div" variant="h4" color="secondary" align="center">Login</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={!!errors.username} fullWidth>
                                <TextField name="username"
                                           variant="outlined"
                                           inputRef={register({required: true})}
                                           id="username"
                                           label="Username"
                                           inputProps={{
                                               maxLength: 100,
                                               autoCorrect: 'off',
                                               autoCapitalize: 'none'
                                           }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl error={!!errors.password} fullWidth>
                                <TextField name="password"
                                           type="password"
                                           variant="outlined"
                                           inputRef={register({required: true})}
                                           id="password"
                                           label="Password"
                                           inputProps={{
                                               maxLength: 50
                                           }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} justify="center">
                        <Box component="span" m={2}>
                            <Button type="submit" variant="contained" color="primary" disabled={requestInProgress}>
                                Login
                            </Button>
                        </Box>
                    </Grid>
                </Box>
            </form>
            <Dialog
                open={failedLoginDialogIsOpen}
                aria-labelledby="failed-login-alert-dialog-title"
                aria-describedby="failed-login-alert-dialog-description"
            >
                <DialogTitle id="failed-login-alert-dialog-title">Login Failed</DialogTitle>
                <DialogContent>
                    <DialogContentText id="failed-login-alert-dialog-description">
                        Invalid username / password combination.  Please ensure your credentials are correct.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFailedLoginDialogClose}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}