import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getClient} from "../../api/api";
import {openGlobalDialog} from "../../reducers/globalDialogSlice";
import Typography from "@material-ui/core/Typography";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {format, parseISO} from "date-fns";
import Behaviors from "./Behaviors";
import Exercises from "./Exercises";
import LabelAndValue from "./LabelAndValue";
import ReactGA from "react-ga";
import Box from "@material-ui/core/Box";

export default function WorkoutDetails(props) {

    const dispatch = useDispatch();

    const client = useSelector(store => store.client);

    const [workout, setWorkout] = React.useState(null);
    const [exercises, setExercises] = React.useState([]);

    const workoutId = props.overview.workoutId;

    const formatDate = (dateString) => {
        return format(parseISO(dateString), 'MM/dd/yyyy');
    }

    const retrieveWorkoutDetails = () => {
        getClient().get('/api/workout/' + client.memberId + '/' + workoutId + '/')
            .then((response) => {
                setWorkout(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve workout.', errorDetails: error.message}));
            });

        getClient().get('/api/workout/' + client.memberId + '/' + workoutId + '/exercises/')
            .then((response) => {
                setExercises(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                dispatch(openGlobalDialog({errorMessage: 'Could not retrieve workout exercises.', errorDetails: error.message}));
            });
        ReactGA.event({
            category: 'Client',
            action: 'View Workout'
        });
    }

    return (
        <Accordion key={workoutId} onChange={retrieveWorkoutDetails} style={{background: '#f5f5f5'}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="workout-{workoutId}"
                id="workout-{workoutId}"
            >
                <Typography variant="h6" color="secondary">{formatDate(props.overview.date)} with {props.overview.trainerName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {workout &&
                    <div>
                        <Exercises key={workoutId} exercises={exercises}/>
                        <Box m={1}>
                            <LabelAndValue label="Type: " value={workout.trainingType} />
                            <LabelAndValue label="Duration: " value={workout.trainingDuration} />
                            <LabelAndValue label="Mood Before/After: " value={workout.preWorkoutMood + '/' + workout.postWorkoutMood} />
                            <LabelAndValue label="How was the schedule created? " value={workout.howWasScheduledCreated} />
                            <LabelAndValue label="Number of times trainer asked client to look at schedule: " value={workout.numberOfTimesTrainerAskClientToLookAtSchedule} />
                            <LabelAndValue label="Number of times trainer chose next exercise; " value={workout.numberOfTimesTrainerChoseNextExercise} />
                            <LabelAndValue label="How many breaks did the client take? " value={workout.numberOfBreaksOverThirtySeconds} />
                            <LabelAndValue label="Number of times redirected: " value={workout.numberOfTimesRedirectedOrOffTask} />
                            <LabelAndValue label="Did client use visual schedule? " value={workout.didClientUseVisualSchedule} />
                            <LabelAndValue label="Who performed the counting? " value={workout.whoCounted} />
                            <LabelAndValue label="Did client stop exercising at completion? " value={workout.didClientStopExercisingAtCompletion} />
                            <Behaviors workout={workout}/>
                            <LabelAndValue label="Notes: " value={workout.notes} />
                        </Box>
                    </div>
                }

            </AccordionDetails>
        </Accordion>
    );
}