import React, {useEffect} from 'react';
import Exercise from "../workout/Exercise";
import {useDispatch, useSelector} from "react-redux";
import {addExercise} from "../../reducers/exercisesSlice";

export default function AssessmentExercises() {

    const exercises = useSelector(store => store.exercises);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(addExercise({type: 'Assessment', name: 'One Minute Step Test'}));
        dispatch(addExercise({type: 'Assessment', name: 'Three Minute Step Test'}));
        dispatch(addExercise({type: 'Assessment', name: 'Plank Hold'}));
        dispatch(addExercise({type: 'Assessment', name: '30 Second Sit to Stand'}));
        dispatch(addExercise({type: 'Assessment', name: '5 Minute Row'}));
        dispatch(addExercise({type: 'Assessment', name: 'Birddog'}));
        dispatch(addExercise({type: 'Assessment', name: 'Static Posture'}));
        dispatch(addExercise({type: 'Assessment', name: 'Overhead Squat'}));
        dispatch(addExercise({type: 'Assessment', name: 'Push Up'}));
    }, []);

    const getExerciseConfigFromName = (exerciseName) => {
        return availableAssessmentExercises.find(exerciseConfig => exerciseConfig.name === exerciseName);
    };

    return (
        <div>
            {exercises.map((exercise, key) => {
                const exerciseConfig = getExerciseConfigFromName(exercise.name);
                return (<Exercise key={key} exercise={exercise} exerciseConfig={exerciseConfig} isAssessmentExercise={true}/>)
            })}
        </div>
    )
}

const availableAssessmentExercises = [
    {
        "name": "Birddog",
        "type": "Assessment",
        "leftArmRightLeg": true,
        "rightArmLeftLeg": true,
        "other": true
    },
    {
        "name": "Chest Press",
        "type": "Assessment",
        "weight": true,
        "equipment": true,
        "reps": true,
        "sets": true,
        "other": true
    },
    {
        "name": "One Minute Step Test",
        "type": "Assessment",
        "granularTime": true,
        "heartRate": true,
        "other": true
    },
    {
        "name": "Overhead Squat",
        "type": "Assessment",
        "other": true
    },
    {
        "name": "Plank Hold",
        "type": "Assessment",
        "granularTime": true,
        "sets": true,
        "other": true
    },
    {
        "name": "Push Up",
        "type": "Assessment",
        "granularTime": true,
        "equipment": true,
        "reps": true,
        "sets": true,
        "other": true
    },
    {
        "name": "Static Posture",
        "type": "Assessment",
        "other": true
    },
    {
        "name": "5 Minute Row",
        "type": "Assessment",
        "granularTime": true,
        "load": true,
        "distance": true,
        "speed": true,
        "other": true
    },
    {
        "name": "30 Second Sit to Stand",
        "type": "Assessment",
        "sitToStandReps": true,
        "other": true
    },
    {
        "name": "Three Minute Step Test",
        "type": "Assessment",
        "granularTime": true,
        "heartRate": true,
        "other": true
    },
];